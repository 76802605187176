export var OVERLAY_Z_INDEX = 10002;
export var SEARCH_BAR_Z_INDEX = 10003;
export var SEARCH_SUGGESTIONS_Z_INDEX = 9999;
export var MODAL_Z_INDEX = 2000;
export var DRAWER_CLOSE_BUTTON_Z_INDEX = 2;
export var BACKDROP_Z_INDEX = 1500;
export var GO_TO_TOP_Z_INDEX = 50;
export var BASE_Z_INDEX = 0;
export var NEGATIVE_BASE_Z_INDEX = -1;
export var STICKY_CONTAINER_BOTTOM_Z_INDEX = 1000;
export var SIDE_NAV_HEADER = 1;
export var YOUTUBE_IFRAME_OVERLAY_ICON = 1;
export var LEGAL_DISCLAIMER_BANNER_Z_INDEX = 2;
export var FIXED_HOMECLUB_CTA_CONTAINER = 99;
